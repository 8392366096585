.icon {
    path {
        fill: #faad14;
    }
}

.modalContent {
    padding: 20px 0;
}

.message {
    margin-top: 16px;
    color: #ff4d4f;
}

.link a {
    color: #1890ff;
    text-decoration: underline;
}