.page {
  padding: 20px;
  padding-top: 40px;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.scoreCards {
  display: flex;
  gap: 24px;
  &__item {
    width: calc(25% - 18px);
    border-radius: 16px;
    padding: 24px;
    width: 100%;
    height: 110px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    background: linear-gradient(90deg, #c7a9ff 0%, #8d9cfc 100%);
    span {
      color: rgb(140, 64, 236);
      color: #fff;
      font-weight: 600;
      font-size: 20px;
      &.score {
        cursor: pointer;
      }
    }
    &:nth-child(2) {
      background: rgb(210, 244, 255);
      background: linear-gradient(90deg, #c7a9ff 0%, #8d9cfc 100%);
      span {
        color: rgb(52, 165, 207);
        color: #fff;
      }
    }
    &:nth-child(2) {
      background: rgb(213, 254, 199);
      background: linear-gradient(90deg, #84c1eb 0%, #39c6b7 100%);
      span {
        color: rgb(94, 176, 61);
        color: #fff;
      }
    }
    &:last-child {
      background: rgb(250, 214, 206);
      background: linear-gradient(90deg, #f38da8 0%, #f58a82 100%);
      span {
        color: rgb(197, 68, 81);
        color: #fff;
      }
    }
  }
}

.mainInfoCards {
  display: flex;
  gap: 24px;
  width: 100%;
  &__item {
    background: rgb(255, 255, 255);
    border-radius: 16px;
    padding: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    overflow: auto;
    gap: 8px;
    &:first-child {
      width: calc(32% - 16px);
    }
    &:nth-child(2) {
      width: calc(50% - 16px);
    }
    &:last-child {
      width: calc(18% - 16px);
    }
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
    }
    &__label {
      color: rgb(99, 115, 129);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      &__wFixed {
        min-width: 200px;
      }
    }
    &__value {
      color: rgb(29, 30, 28);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: right;
      &__wFixed {
        min-width: 80px;
      }
    }
  }
}

.errorWorkCard {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  overflow: auto;
  &__row {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__column {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &__label {
    width: calc(20%);
    min-width: 250px;
    color: rgb(99, 115, 129);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  &__question {
    padding: 0 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    &__item {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgb(239, 242, 248);
      border-radius: 8px;
      font-size: 16px;
      font-weight: normal;
      color: rgb(162, 166, 184);
      cursor: pointer;
      &__true {
        background: linear-gradient(90deg, #84c2eb 0%, #3ac7b8 100%);
        color: #fff;
      }
      &__false {
        background: linear-gradient(90deg, #f38da8 0%, #f58a82 100%);
        color: #fff;
      }
      &__isNull {
        background: rgb(239, 242, 248);
        color: rgb(162, 166, 184);
      }
    }
  }
}
