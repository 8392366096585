.customCheckbox {
  display: flex;
  align-items: center;
  color: #000;
  text-align: center;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  &:hover {
    .ant-checkbox-inner {
      background-color: transparent;
      border-color: transparent !important;
    }
  }
  .ant-checkbox {
    background: #fff;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-checkbox-inner {
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
    &.ant-checkbox-checked .ant-checkbox-inner {
      background-color: #fff !important;
      border-color: transparent !important;
    }
    &:hover {
      .ant-checkbox-inner {
        border-color: transparent !important;
      }
    }
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      &::after {
        border: 2px solid #000;
        border-top: 0;
        border-left: 0;
      }
    }
  }
  .checkboxLabel {
    font-size: 18px;
    color: #fff;
    margin-left: 10px;
  }
}
