.questionContent {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.options {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.option {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 6px;
    font-size: 16px;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    transition: background-color 0.3s ease;
    &__correct {
        background-color: #e6ffed !important;
        border-color: #52c41a !important;
    }
    &__inCorrect {
        // background-color: #fff1f0;
        // border-color: #ff4d4f;
    }
    &__correctNSelected {
        background-color: #67e78b !important;
        border-color: #42b709 !important;
    }
}

.optionLabel {
    margin-right: 12px;
    font-size: 18px;
}

.selectedOption {
    background-color: #fff1f0;
    border: 1px solid #ff4d4f;
}