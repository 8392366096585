.body {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.title {
  color: var(--black, #343434);
  text-align: center;
  font-family: Gilroy;
  font-size: 32px;
  font-weight: 700;
}

.testBlock {
  position: relative;
  height: 100%;
  min-height: 800px;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding: 40px;
  background: #eaf9ff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &__head {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__body {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__title {
    color: #000;
    text-align: center;
    font-family: Gilroy;
    font-size: 32px;
    font-weight: 700;
    line-height: 140%;
  }
  &__subtitle {
    color: #000;
    font-family: Gilroy;
    font-size: 24px;
    font-weight: 700;
    line-height: 140%;
    text-align: center;
  }
  &__time {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    &__label {
      color: #000;
      font-family: Gilroy;
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
      opacity: 0.7;
    }
    &__value {
      padding: 10px;
      padding-right: 40px;
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.1);
      color: #000;
      font-family: Gilroy;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      opacity: 0.7;
    }
  }
  &__checkboxes {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    &__item {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  &__button {
    // position: absolute;
    // right: 40px;
    // bottom: 40px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 6px;
    border: 1px solid #000;
    color: #000;
    text-align: center;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    transition: 0.3s all;

    &__back {
      // right: unset;
      // left: 40px;
      svg {
        rotate: 180deg;
        path {
          fill: #000;
        }
      }
    }
    &__start {
      // padding: 16px 24px;
      border-radius: 8px;
      background: #000;
      color: #8e9dfd;
      text-align: center;
      font-family: Gilroy;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
    &__disabled {
      cursor: not-allowed;
      background: var(--Line, rgba(0, 0, 0, 0.1)) !important;
      color: #000;
    }
  }
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
